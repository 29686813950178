
import { Component, Vue } from 'vue-property-decorator';
import { Header } from '@/components/offers';

// Config Variables
import { ActiveTenant } from '../../../active-tenant';
import { tenants } from '../../../tenant-config';
import { IAuthUser } from '@/types/user.types';
import { UserModule } from '@/store/modules';

@Component({ components: { Header } })
export default class Causes extends Vue {
  currentTenant = tenants[ActiveTenant];
  titleTextColor = tenants[ActiveTenant].text.impactDashboardTitleColor;

  get user(): IAuthUser | null {
    return UserModule.user;
  }

  get currentCause(): any {
    return this.user?.cause;
  }

  get headerImageStyle(): any {
    return {
      '--darken': '0.4',
      height: 'var(--image-height)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '40px',
      // // NOTE: This color should NEVER be present/visible!
      backgroundColor: 'black',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url('${this.currentCause?.logoUrl}')`
    };
  }
}
